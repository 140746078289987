import Vue from "vue";

import "./permissionsEnum.js";

import Cliente from "@/module/cliente/view/Cliente";
import UsuarioCgi from "@/module/usuario/view/UsuarioCgi";
import ProxyCgi from "@/module/proxy/view/ProxyCgi";
import ClienteFirebase from "@/module/clienteFirebase/view/ClienteFirebase";
import ClientePorApp from "@/module/clienteFirebase/view/ClientePorApp";
import Modulo from "@/module/modulo/view/Modulo";
import AcessoApp from "@/module/acessoApp/view/AcessoApp";
import Projeto from "@/module/projetoFirebase/view/ProjetoFirebase";
import AuditoriaCgi from "@/module/auditoria/view/AuditoriaCgi";
import LogsAppCgi from "@/module/logsApp/view/LogsAppCgi";
import LogsDesktopCgi from "@/module/logsDesktop/view/LogsDesktopCgi";
import StatusServidor from "@/module/statusServidor/view/StatusServidor";
import CertificadoDigital from "@/module/certificadoDigital/view/CertificadoDigital";
import BancosDados from "@/module/bancoDados/view/BancoDados";
import CadastroDelta from "@/module/cadastroDelta/view/CadastroDelta";
import AplicacaoDelta from "@/module/aplicacaoDelta/view/AplicacaoDelta";

export const menuItens = [
  {
    title: "Cliente",
    text: "Cliente",
    icon: "mdi-account-group",
    permission: Vue.prototype.clientePermissionId,
    children: [
      {
        title: "Cadastro",
        text: "Cadastro",
        icon: "mdi-account-group",
        screen: Cliente,
        permission: Vue.prototype.clientePermissionId,
      },
      {
        title: "Clientes do firebase",
        text: "Clientes do firebase",
        screen: ClienteFirebase,
        icon: "mdi-clipboard-account-outline",
        permission: Vue.prototype.clientePermissionId,
      },
    ],
  },
  {
    title: "Configurações",
    text: "Configurações",
    icon: "mdi-cog",
    permission: null,
    children: [
      {
        title: "Projetos",
        text: "Projetos",
        screen: Projeto,
        icon: "mdi-arrange-bring-to-front",
        permission: Vue.prototype.projetoPermissionId,
      },
      {
        title: "Usuários",
        text: "Usuários",
        screen: UsuarioCgi,
        icon: "mdi-account-multiple",
        permission: Vue.prototype.usuarioPermissionId,
      },
      {
        title: "Bancos de Dados",
        text: "Bancos de Dados",
        screen: BancosDados,
        icon: "mdi-database-cog",
      },
    ],
  },
  {
    title: "Observabilidade",
    text: "Observabilidade",
    icon: "mdi-file-eye",
    permission: null,
    children: [
      {
        title: "Status Servidores",
        text: "Status Servidores",
        screen: StatusServidor,
        icon: "mdi-keyboard-variant",
        permission: null,
      },
      {
        title: "Logs Aplicativos",
        text: "Logs Aplicativos",
        screen: LogsAppCgi,
        icon: "mdi-clipboard-text",
        permission: Vue.prototype.logAppPermissionId,
      },
      {
        title: "Logs Desktop",
        text: "Logs Desktop",
        screen: LogsDesktopCgi,
        icon: "mdi-clipboard-pulse",
        permission: Vue.prototype.logDesktopPermissionId,
      },
    ],
  },
  {
    title: "Relatórios",
    text: "Relatórios",
    icon: "mdi-chart-pie",
    permission: null,
    children: [
      {
        title: "Acessos",
        text: "Acessos",
        screen: AcessoApp,
        icon: "mdi-file-cloud",
        permission: Vue.prototype.acessoAppPermissionId,
      },
      {
        title: "Auditoria",
        text: "Auditoria",
        screen: AuditoriaCgi,
        icon: "mdi-alert-octagon",
        permission: Vue.prototype.auditoriaPermissionId,
      },
      {
        title: "Certificados Digitais",
        text: "Certificados Digitais",
        screen: CertificadoDigital,
        icon: "mdi-file-chart",
        permission: Vue.prototype.clientePermissionId,
      },
      {
        title: "Clientes por aplicativo",
        text: "Clientes por aplicativo",
        screen: ClientePorApp,
        icon: "mdi-chart-tree",
        permission: Vue.prototype.clientePermissionId,
      },
      {
        title: "Modulos",
        text: "Modulos",
        screen: Modulo,
        icon: "mdi-view-module",
        permission: Vue.prototype.clientePermissionId,
      },
      {
        title: "Proxy",
        text: "Proxy",
        screen: ProxyCgi,
        icon: "mdi-clouds",
        permission: Vue.prototype.proxyPermissionId,
      },
    ],
  },
  {
    title: "Deltas",
    text: "Deltas",
    icon: "mdi-database",
    permission: null,
    children: [
      {
        title: "Cadastros de Delta",
        text: "Cadastros de Delta",
        icon: "mdi-database-plus",
        screen: CadastroDelta,
      },
      {
        title: "Aplicação de Delta",
        text: "Aplicação de Delta",
        icon: "mdi-database-refresh",
        screen: AplicacaoDelta,
      },
    ],
  },
];
