import { colunas } from "../const/colunas";
import { defaultClienteFirebase } from "../domain/model/clienteFirebase";
import animationData from "../../../assets/search.json";
import _ from "lodash";
import { numberRule0And999, numberRule0And9999 } from "@/core/const/rules";
import { snackbar } from "@/core/const/devSnackbar";
import { alert } from "@/core/const/devAlert";
import Prism from "prismjs";
import "prismjs/components/prism-bash";
import { v4 as uuidv4 } from "uuid";

class ClienteFirebaseController {
  dialog = false;
  colunas = colunas;
  clientes = [];
  clienteFirebase = defaultClienteFirebase();
  projeto = {};
  paginacao = {};
  animacao = { animationData: animationData };
  numberRule0And999 = numberRule0And999;
  numberRule0And9999 = numberRule0And9999;
  firebaseCmd = "Realizando request";
  cmd = false;

  constructor(
    context,
    buscaClientesUseCase,
    criaClienteFirebaseUseCase,
    excluiClienteFirebaseUseCase
  ) {
    this.context = context;
    this.buscaClientesUseCase = buscaClientesUseCase;
    this.criaClienteFirebaseUseCase = criaClienteFirebaseUseCase;
    this.excluiClienteFirebaseUseCase = excluiClienteFirebaseUseCase;
  }

  async adicionarRegistro() {
    try {
      this.dialog = true;
    } catch (error) {
      // snackbar.show({message: error})
    }
  }

  async alterarRegistro(item) {
    try {
      this.clienteFirebase = { ...item };
      this.dialog = true;
    } catch (error) {
      // snackbar.show({message: error})
    }
  }

  cancelar() {
    this.clienteFirebase = defaultClienteFirebase();
    this.context.$refs.form.resetValidation();
    this.dialog = false;
  }

  async salvar() {
    const hash = uuidv4();
    const eventSource = new EventSource(`https://ntfy.sh/${hash}/sse`);
    eventSource.onmessage = (e) => {
      this.firebaseCmd += Prism.highlight(
        `\n${JSON.parse(e.data)?.message}`,
        Prism.languages.shell
      );
    };

    try {
      if (this.context.$refs.form.validate()) {
        this.cmd = true;
        await this.criaClienteFirebaseUseCase(
          this.clienteFirebase,
          this.projeto.firebase,
          hash
        );

        snackbar.show({
          message: "Registro salvo com sucesso!",
          color: "green",
          timeout: 3000,
        });

        this.cmd = false;
        this.dialog = false;
        this.firebaseCmd = "Realizando request";

        this.cancelar();

        this.clientes = await this.buscaClientesUseCase(this.projeto.firebase);
      }
    } catch (error) {
      // snackbar.show({message: error})
    } finally {
      eventSource.close();
    }
  }

  async excluirRegistro(item) {
    const hash = uuidv4();
    const eventSource = new EventSource(`https://ntfy.sh/${hash}/sse`);
    eventSource.onmessage = (e) => {
      this.firebaseCmd += Prism.highlight(
        `\n${JSON.parse(e.data)?.message}`,
        Prism.languages.shell
      );
    };

    try {
      const deletar = await alert.confirm({
        message: "Deseja realmente excluir o registro?",
        color: "red",
      });

      if (deletar) {
        this.cmd = true;
        await this.excluiClienteFirebaseUseCase(
          item.id,
          this.projeto.firebase,
          hash
        );
      }

      this.firebaseCmd = "Realizando request";
      this.cmd = false;
      this.clientes = await this.buscaClientesUseCase(this.projeto.firebase);
    } catch (error) {
      // snackbar.show({message: error})
    } finally {
      eventSource.close();
    }
  }

  async projetoSelecionado(projeto) {
    try {
      this.projeto = projeto;
      this.clientes = await this.buscaClientesUseCase(projeto.firebase);
    } catch (error) {
      // snackbar.show({message: error})
    }
  }
}

export default ClienteFirebaseController;
