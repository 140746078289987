export const colunas = [
    {
        text: "Nome",
        value: "nome_cli"
    },
    {
        text: "Código",
        value: "id"
    },
    {
        text: "Pacific",
        value: "endereco_pacific"
    },
    {
        text: "Ativo",
        value: "ativo",
        custom: true,
        align: "center",
        sortable: false
    },
]

export const colunasMaisVendas = [
    {
        text: "A. de usados",
        value: "modulo_avaliacao_usados",
        custom: true,
        align: "center",
        sortable: false
    },
    {
        text: "Localização",
        value: "modulo_geo_posicionamento",
        custom: true,
        align: "center",
        sortable: false
    },
    {
        text: "Negócios",
        value: "modulo_mais_negocios",
        custom: true,
        align: "center",
        sortable: false
    },
    {
        text: "Pedidos",
        value: "modulo_mais_pedidos",
        custom: true,
        align: "center",
        sortable: false
    },
    {
        text: "Usados",
        value: "modulo_mais_usados",
        custom: true,
        align: "center",
        sortable: false
    },
    {
        text: "Visitas",
        value: "modulo_visitas",
        custom: true,
        align: "center",
        sortable: false
    },
]