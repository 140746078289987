<template>
  <div>
    <v-row>
      <v-col cols="12" lg="3">
        <projetos-firebase
          @projeto-selecionado="controller.projetoSelecionado($event)"
        />
      </v-col>
      <v-col cols="12" lg="9">
        <dev-tabela
          v-if="controller.projeto.firebase"
          nome-tabela="Clientes do firebase"
          :linhas="controller.clientes"
          :colunas="controller.colunas"
          mostra-acoes
          @alterar-item="controller.alterarRegistro($event)"
          @deletar-item="controller.excluirRegistro($event)"
          mostra-pesquisa
          :mostra-paginacao="false"
        >
          <template v-slot:ativo="{ item }">
            <div class="d-flex justify-center">
              <v-checkbox
                dense
                hide-details
                v-model="item.ativo"
                readonly
              ></v-checkbox>
            </div>
          </template>
          <template v-slot:botoes>
            <dev-btn incluir @click="controller.adicionarRegistro()"></dev-btn>
          </template>
        </dev-tabela>
        <div
          v-else
          class="d-flex flex-column justify-center align-center font-weight-bold headline"
          style="height: calc(100vh - 200px)"
        >
          <div>Selecione um projeto</div>
          <dev-lottie
            class="mt-10"
            :options="controller.animacao"
            :height="300"
            :width="400"
          />
        </div>
      </v-col>
    </v-row>

    <dev-dialog
      :dialog="controller.dialog"
      titulo="Cadastro"
      @cancelar="controller.cancelar()"
      @salvar="controller.salvar()"
    >
      <template v-slot:form>
        <v-form ref="form">
          <div>
            <v-row no-gutters>
              <v-col>
                <v-switch
                  inset
                  label="Ativo"
                  dense
                  hide-details
                  :class="{
                    'pb-4':
                      controller.projeto.firebase !== 'mais-vendas' &&
                      controller.projeto.firebase !== 'flutter-mais-vendas',
                  }"
                  v-model="controller.clienteFirebase.ativo"
                ></v-switch>
                <v-switch
                  v-if="
                    controller.projeto.firebase === 'mais-vendas' ||
                    controller.projeto.firebase === 'flutter-mais-vendas'
                  "
                  inset
                  label="Avaliação de usados"
                  dense
                  hide-details
                  v-model="controller.clienteFirebase.modulo_avaliacao_usados"
                ></v-switch>
                <v-switch
                  v-if="
                    controller.projeto.firebase === 'mais-vendas' ||
                    controller.projeto.firebase === 'flutter-mais-vendas'
                  "
                  inset
                  label="Mais negócios"
                  dense
                  hide-details
                  v-model="controller.clienteFirebase.modulo_mais_negocios"
                ></v-switch>
                <v-switch
                  class="pb-4"
                  inset
                  label="Documentos do Vendedor"
                  dense
                  hide-details
                  v-model="
                    controller.clienteFirebase.modulo_documentos_do_vendedor
                  "
                ></v-switch>
              </v-col>
              <v-col
                v-if="
                  controller.projeto.firebase === 'mais-vendas' ||
                  controller.projeto.firebase === 'flutter-mais-vendas'
                "
              >
                <v-switch
                  inset
                  label="Mais pedidos"
                  dense
                  hide-details
                  v-model="controller.clienteFirebase.modulo_mais_pedidos"
                ></v-switch>
                <v-switch
                  inset
                  label="Mais usados"
                  dense
                  hide-details
                  v-model="controller.clienteFirebase.modulo_mais_usados"
                ></v-switch>
                <v-switch
                  inset
                  label="Visitas"
                  dense
                  hide-details
                  v-model="controller.clienteFirebase.modulo_visitas"
                ></v-switch>
                <v-switch
                  class="pb-4"
                  inset
                  label="Autorização de Faturamento"
                  dense
                  hide-details
                  v-model="
                    controller.clienteFirebase.modulo_autorizacao_de_faturamento
                  "
                ></v-switch>
              </v-col>
            </v-row>
          </div>
          <v-text-field
            outlined
            rounded
            dense
            label="Código firebase"
            placeholder="cgi-121"
            :rules="[(v) => !!v || 'Preencha o campo']"
            v-model="controller.clienteFirebase.id"
          ></v-text-field>
          <v-text-field
            outlined
            rounded
            dense
            label="Nome"
            :rules="[(v) => !!v || 'Preencha o campo']"
            v-model="controller.clienteFirebase.nome_cli"
          ></v-text-field>
          <v-text-field
            outlined
            rounded
            dense
            label="Código cliente na CGI"
            :rules="[controller.numberRule0And9999]"
            v-model.number="controller.clienteFirebase.cod_cli"
            type="number"
          ></v-text-field>
          <v-text-field
            outlined
            rounded
            dense
            label="Dias autenticação"
            type="number"
            v-model.number="controller.clienteFirebase.dias_autenticacao"
          ></v-text-field>
          <v-text-field
            outlined
            rounded
            dense
            label="Data validade"
            :rules="[(v) => !!v || 'Preencha o campo']"
            v-model="controller.clienteFirebase.dt_validade"
          ></v-text-field>
          <v-text-field
            outlined
            rounded
            dense
            label="Pacific"
            :rules="[(v) => !!v || 'Preencha o campo']"
            v-model="controller.clienteFirebase.endereco_pacific"
          ></v-text-field>
          <v-text-field
            outlined
            rounded
            dense
            label="Black List"
            :rules="[(v) => !!v || 'Preencha o campo']"
            v-model="controller.clienteFirebase.black_list"
          ></v-text-field>
          <v-text-field
            outlined
            rounded
            dense
            type="number"
            label="Número de devices por usuário"
            :rules="[(v) => !!v || 'Preencha o campo']"
            v-model.number="controller.clienteFirebase.num_devices_vendedor"
          ></v-text-field>
          <v-text-field
            outlined
            rounded
            dense
            label="Número de devices"
            :rules="[(v) => !!v || 'Preencha o campo']"
            v-model.number="controller.clienteFirebase.num_devices"
            type="number"
          ></v-text-field>
          <v-text-field
            outlined
            rounded
            dense
            label="Versão minima"
            :rules="[(v) => !!v || 'Preencha o campo']"
            v-model.number="controller.clienteFirebase.versao_minima"
            type="number"
          ></v-text-field>
          <v-text-field
            outlined
            rounded
            dense
            label="Versão serviço"
            :rules="[(v) => !!v || 'Preencha o campo']"
            v-model.number="controller.clienteFirebase.versao_servico"
            type="number"
          ></v-text-field>

          <template v-if="controller.projeto.firebase === 'despesas-de-viagem'">
            <v-text-field
              outlined
              rounded
              dense
              label="Usuário"
              v-model="controller.clienteFirebase.usuarioP"
            ></v-text-field>

            <v-text-field
              outlined
              rounded
              dense
              label="Senha"
              v-model="controller.clienteFirebase.senhaP"
            ></v-text-field>
          </template>
        </v-form>
      </template>
    </dev-dialog>

    <v-dialog v-model="controller.cmd" content-class="rounded-xl" width="800">
      <v-card color="#212121">
        <v-card-title class="white--text"> Servidor </v-card-title>
        <v-card-text class="pa-0">
          <pre><code class="language-json pa-5" style="background-color: transparent; color: white; font-size: 12px" v-html="controller.firebaseCmd"></code></pre>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { clienteFirebaseController } from "@/di/di";
import ProjetoFirebase from "@/module/projetoFirebase/view/ProjetoFirebase";
export default {
  name: "ProjetoFirebase",
  components: {
    "projetos-firebase": ProjetoFirebase,
  },
  data: (context) => ({
    controller: clienteFirebaseController(context),
  }),
};
</script>

<style scoped>
code {
  display: inline-block;
  overflow-x: auto;
  width: 100%;
}
:deep(.token.punctuation) {
  color: #ffffff;
}
</style>
