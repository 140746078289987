import { defaultAcessoApp } from "@/module/acessoApp/domain/model/acessoApp";
import { defaultPaginacao } from "@/core/domain/model/paginacao";
import { colunas } from "../const/colunas";
import Prism from "prismjs";
import 'prismjs/components/prism-json';
import { isJSON } from "@/core/const/util";
import { snackbar } from "@/core/const/devSnackbar";

class LogsAppController {
  colunas = colunas
  logsApp = defaultAcessoApp()
  logsAppPaginacao = defaultPaginacao()
  paginacao = {}
  dialog = false
  carregando = false

  constructor(
    context,
    buscaLogsAppUseCase
  ) {
    this.context = context
    this.buscaLogsAppUseCase = buscaLogsAppUseCase
  }

  async mounted(paginacao) {
    try {
      this.paginacao = paginacao
      this.logsAppPaginacao = await this.buscaLogsAppUseCase({ ...paginacao })
    } catch (error) {
      // snackbar.show({message: error})
    }
  }

  async atualizar() {
    try {
      this.carregando = true
      this.logsAppPaginacao = await this.buscaLogsAppUseCase(this.paginacao)
    } finally {
      this.carregando = false
    }
  }

  async clickLogs() {
    try {
      this.dialog = true
    } catch (error) {
      // snackbar.show({message: error})
    }
  }

  cancelar() {
    this.dialog = false
  }

  linhaSelecionada(linha) {
    this.logsApp = linha[0]

    this.logsApp.chamada = Prism.highlight(
      JSON.stringify(JSON.parse(this.logsApp.chamada), null, 4),
      Prism.languages.json
    );

    if (this.logsApp.resposta.includes("<html>")) {
      this.logsApp.resposta = Prism.highlight(
        this.logsApp.resposta,
        Prism.languages.html
      );
      return
    }

    this.logsApp.resposta = Prism.highlight(
      JSON.stringify(isJSON(this.logsApp.resposta) ? JSON.parse(this.logsApp.resposta) : this.logsApp.resposta, null, 4),
      Prism.languages.json
    );

  }
}

export default LogsAppController